.title {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 90%;
}
.image {
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
}
